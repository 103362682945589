import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import DataTable from "react-data-table-component"
import { Marker, Popup } from "react-leaflet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Map from "../components/Map"

function markerbbox(markers) {
  var result = [
    [Infinity, Infinity],
    [-Infinity, -Infinity],
  ]
  markers.forEach(coord => {
    if (result[0][0] > coord[0]) {
      result[0][0] = coord[0]
    }
    if (result[0][1] > coord[1]) {
      result[0][1] = coord[1]
    }
    if (result[1][0] < coord[0]) {
      result[1][0] = coord[0]
    }
    if (result[1][1] < coord[1]) {
      result[1][1] = coord[1]
    }
  })
  return result
}

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const heroImage = data?.heroImage?.childImageSharp?.fluid
  const seoImage = data.heroImage ? data.heroImage.childImageSharp.resize : null

  /* Define Columns for the walk list */
  const tableColumns = [
    {
      name: "Walk",
      selector: "walk",
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      name: "Distance (km)",
      selector: "distance",
      sortable: true,
      grow: 1,
      compact: true,
    },
    {
      name: "Ascent (m)",
      selector: "ascent",
      sortable: true,
      grow: 1,
      compact: true,
    },
    {
      name: "Difficulty",
      selector: "difficulty",
      sortable: true,
      grow: 1,
      hide: "sm",
      compact: true,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      grow: 5,
      wrap: true,
      hide: "sm",
    },
  ]

  var rowID = 1
  /* Populate rows from the post frontmatter */
  const tableData = posts.map(post => {
    const title = post.frontmatter.title || post.fields.slug
    const distanceKm = parseFloat(
      post.frontmatter.gpx.childGpx.gpx.tracks[0].distance.total / 1000
    ).toFixed(1)
    const ascentMetres = parseFloat(
      post.frontmatter.gpx.childGpx.gpx.tracks[0].elevation.pos
    ).toFixed(0)
    const difficulty = post.frontmatter.difficulty
    const description = post.frontmatter.description

    return {
      id: rowID++ /* dataTable library likes having an ID for the rows */,
      walk: (
        <Link to={post.fields.slug} itemProp="url">
          {title}
        </Link>
      ),
      distance: distanceKm,
      ascent: ascentMetres,
      difficulty: difficulty,
      description: description,
    }
  })

  const markerList = posts.map(post => {
    return post.frontmatter.gpx.childGpx.geojson.routeStart
  })

  const mapBounds = markerbbox(markerList)

  /* Data available case */
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Peak District and UK Hikes"
        image={seoImage}
        pathname={location.pathname}
      />
      <div class="mx-n3">
        <Image fluid={heroImage} alt="Stanage edge in the golden light" />
      </div>
      <div class="m-md-3">
        <h1>Welcome to Hike Log</h1>
        <p>
          Hike Log is an index of walks in the Peak District and the rest of the
          UK. This home page provides an interactive map and a table listing all
          the walks available on this site. Each walk has a page that includes a
          map of the route, elevation data and other useful statistics. I've
          also included my reflections on the walk and my favourite pictures.
        </p>
        <p>
          See the <a href="/about/">about</a> page for help on using this
          website.
        </p>

        <Map bounds={mapBounds}>
          {posts.map(post => {
            return (
              <Marker
                position={post.frontmatter.gpx.childGpx.geojson.routeStart}
              >
                <Popup>
                  <strong>
                    <Link to={post.fields.slug} itemProp="url">
                      {post.frontmatter.title || post.fields.slug}
                    </Link>
                  </strong>
                  <dl>
                    <dt></dt>
                  </dl>
                </Popup>
              </Marker>
            )
          })}
        </Map>
        <DataTable
          striped
          dense
          noHeader
          columns={tableColumns}
          data={tableData}
        />
        <hr />
        <strong>Tip: </strong>
        <em>
          The table above can be sorted by clicking on each column header. You
          may find only limited information is being displayed due to your
          screen size. You can either follow the links to the detailed page;
          rotate your device for landscape mode; or use a larger screen.
        </em>
        <hr />
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    heroImage: file(absolutePath: { regex: "/indexhero.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
          difficulty
          gpx {
            childGpx {
              geojson {
                routeStart
                durationms
              }
              gpx {
                tracks {
                  distance {
                    total
                  }
                  elevation {
                    pos
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
